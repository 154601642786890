<template>

    <div class="col-lg-12" style="user-select: none;">
      <div class="row">

        <div class="col-12 mb-2">
          <div class="row">
            <div class="col-8" style="font-size:large;">
              <p>
                <fa icon="caret-left" @click="gotoPrevDate" style="cursor: pointer; margin-right:6px;"></fa>  {{ currentDateFormatted }}  <fa icon="caret-right"  @click="gotoNextDate" style="cursor: pointer; margin-left:6px;"></fa>
               </p>

            </div>
            <div class="col-4">
               <Datepicker v-model="selectedDate" @update:modelValue="changeDate"  locale="tr" selectText="Seç" cancelText="Vazgeç">
                <template #trigger>
                  <p class="clickable-text" style="cursor: pointer;"> <fa icon="history"></fa> Tarih Seç</p>
                </template>
              </Datepicker>
              </div>
          </div>
        </div>

        <table class="table table-sm table-striped w-100" v-if="dataNotFound">
          <thead>
          <tr>
            <th style="text-align:center;" width="60">
              Girdiğiniz tarihe ait fiyat bulunamdı
            </th>

          </tr>
          </thead>
        </table>

        <table class="table table-sm table-striped" v-if="!dataNotFound">
          <thead>
          <tr>
            <th style="text-align:center;" width="60">
              <fa icon="arrow-up" class="p-1"></fa>
              <fa icon="arrow-down" class="p-1"></fa>
            </th>
            <th>
              Metal
            </th>
            <th>
              Fiyat USD (Ton)
            </th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="item in metalPriceList" :key="item.id" >
            <td style="text-align:center;">
              <fa icon="caret-down" color="red" v-if="item.price < item.oldPrice"></fa>
              <fa icon="caret-up" color="green" v-if="item.price > item.oldPrice"></fa>
              <fa icon="minus" color="black" v-if="item.price == item.oldPrice"></fa>
            </td>
            <td>
              {{ item.resourceName }}
            </td>
            <td>
              $ {{ item.price }}
            </td>

          </tr>



          </tbody>
        </table>

      </div>
    </div>

</template>

<script>

import moment from "moment";
import PriceService from "@/services/priceService";
import Datepicker from "@vuepic/vue-datepicker";

export default {
  name : "DailyMetalPriceView",
  _priceService: null,
  components: {Datepicker},
  created() {
    this._priceService = new PriceService();
    if(this.$route.params.requestedDate != null)
    {
      this.currentDate = moment(this.$route.params.requestedDate);
    }
    else {
      this.currentDate = 0;
    }

  },
  mounted() {
    this.refreshValues();
  },
  data() {
    return {
      currentDate: moment(),
      currentDateFormatted:moment(this.currentDate).locale('tr').format("DD MMMM yy"),
      metalPriceList:[],
      selectedDate:this.currentDate,
      dataNotFound:false
    }

  },
  methods: {
    gotoPrevDate() {
      this.currentDate = moment(this.currentDate).add(-1, "day");
      this.refreshValues();
    },
    gotoNextDate() {
      this.currentDate = moment(this.currentDate).add(1, "day");
      this.refreshValues();
    },
    refreshValues() {
      this._priceService.getLatestPrices(this.currentDate).then(response => {
        if(response.lastDate != null && response.lastDate != "")
        {
          this.currentDateFormatted = moment(response.lastDate).locale('tr').format("DD MMMM yy");
          this.currentDate = moment(response.lastDate);
          this.metalPriceList = response.list;
          this.dataNotFound =false;
        }
        else {
          this.currentDateFormatted = moment(this.currentDate).locale('tr').format("DD MMMM yy");
          this.dataNotFound = true;
        }
      });
    },
    changeDate(modelData) {
      this.currentDate = moment(modelData);
      this.refreshValues();
    }
  }
}
</script>