import appAxios from '@/utils/appAxios'

export default class PriceService
{
    async getLatestPrices(requestedDate) {
        const response = await appAxios.get('/api/Price/latestPrices?requestedDate=' + requestedDate);
        if(response.status === 200)
        {
            return response.data;
        }
        {
            return [];
        }
    }

    async getChartData(resourceId, month) {
        const response = await appAxios.get('/api/Price/charts?resourceId=' + resourceId + '&month=' + month);
        if(response.status === 200)
        {
            return response.data;
        }
        {
            return [];
        }
    }

    async getChartDataEnglish(resourceId, month) {
        const response = await appAxios.get('/api/Price/chartsEng?resourceId=' + resourceId + '&month=' + month);
        if(response.status === 200)
        {
            return response.data;
        }
        {
            return [];
        }
    }


}