<template>
  <div>
    <center>404 <br> Page Not Found</center>

  </div>

</template>
<script>
export default {
  name: "HomePage"
}
</script>