import { createRouter, createWebHistory } from 'vue-router'
import dailyMetalPriceView from "@/views/DailyMetalPriceView";
import dailyMetalPriceViewEn from "@/views/DailyMetalPriceViewEn";
import homePage from "@/views/HomePage";
import dailyMetalPriceHomeView from "@/views/DailyMetalPriceHomeView";
import dailyMetalPriceHomeViewEn from "@/views/DailyMetalPriceHomeViewEn";
import ApexChartView from "@/views/ApexChartView";
import ApexChartViewEn from "@/views/ApexChartViewEn";

const routes = [
  {
    path: '/',
    name: 'home',
    component: homePage
  },
  {
    path: '/DailyMetalPrice',
    name: 'dailyMetalPrice',
    component: dailyMetalPriceView
  },
  {
    path: '/DailyMetalPriceEn',
    name: 'dailyMetalPriceEn',
    component: dailyMetalPriceViewEn
  },
  {
    path: '/DailyMetalPriceHome',
    name: 'dailyMetalPriceHome',
    component: dailyMetalPriceHomeView
  },
  {
    path: '/DailyMetalPriceHomeEn',
    name: 'dailyMetalPriceHomeEn',
    component: dailyMetalPriceHomeViewEn
  },
  {
    path: '/DailyMetalPrice/:requestedDate',
    name: 'dailyMetalPriceWithDate',
    component: dailyMetalPriceView
  },
  {
    path: '/Chart/:resourceId',
    name: 'chartByResource',
    component: ApexChartView
  },
  {
    path: '/ChartEn/:resourceId',
    name: 'chartByResourceEn',
    component: ApexChartViewEn
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router