<template>
  <div>
    <div class="toolbar">
      <button id="one_month" @click="updateData('one_month')" :class="{active: selection==='one_month'}">1 Ay</button>
      <button id="three_months" @click="updateData('three_months')" :class="{active: selection==='three_months'}">3 Ay</button>
      <button id="six_months" @click="updateData('six_months')" :class="{active: selection==='six_months'}">6 Ay</button>
      <button id="one_year" @click="updateData('one_year')" :class="{active: selection==='one_year'}"> 1 Yıl</button>
    </div>
    <br>
    <apexchart type="area" :options="chartOptions" :series="series" locale="tr"></apexchart>
  </div>
</template>

<script>

import PriceService from "@/services/priceService";

export default {
  name: "ApexChartView",
  _service: null,
  created() {
    this._service = new PriceService();
    this.resourceId = this.$route.params.resourceId;
    this.month = 3;
  },
  mounted() {
    this.fillData();
  },
  data() {
    return {
      selection:"three_months",
      resourceId: 0,
      chartDataList: "",
      chartData: {},
      month: 6,
      monthDataSource: [
        {
          name: " 3 Aylık",
          id: 3
        },
        {
          name: " 6 Aylık",
          id: 6
        }, {
          name: " 12 Aylık",
          id: 12
        }
      ],
      series: [],
      chartOptions: {
        chart: {
          id: 'area-datetime',
          type: 'area',
          height: 350,
          zoom: {
            autoScaleYaxis: true
          },
          locales: [
            {
              "name": "tr",
              "options": {
                "months": [
                  "Ocak",
                  "Şubat",
                  "Mart",
                  "Nisan",
                  "Mayıs",
                  "Haziran",
                  "Temmuz",
                  "Ağustos",
                  "Eylül",
                  "Ekim",
                  "Kasım",
                  "Aralık"
                ],
                "shortMonths": [
                  "Oca",
                  "Şub",
                  "Mar",
                  "Nis",
                  "May",
                  "Haz",
                  "Tem",
                  "Ağu",
                  "Eyl",
                  "Eki",
                  "Kas",
                  "Ara"
                ],
                "days": [
                  "Pazar",
                  "Pazartesi",
                  "Salı",
                  "Çarşamba",
                  "Perşembe",
                  "Cuma",
                  "Cumartesi"
                ],
                "shortDays": ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
                "toolbar": {
                  "exportToSVG": "SVG İndir",
                  "exportToPNG": "PNG İndir",
                  "exportToCSV": "CSV İndir",
                  "menu": "Menü",
                  "selection": "Seçim",
                  "selectionZoom": "Seçim Yakınlaştır",
                  "zoomIn": "Yakınlaştır",
                  "zoomOut": "Uzaklaştır",
                  "pan": "Kaydır",
                  "reset": "Yakınlaştırmayı Sıfırla"
                }
              }
            }
          ],
          defaultLocale: 'tr'
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          style: 'hollow',
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: 'dd MMMM yyyy'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100]
          }
        },
      },
    }
  },
  methods: {
    updateData(timeline) {
      this.selection = timeline
      switch (timeline) {
        case 'one_month':
         this.month = 1;
         this.fillData();
          break
        case 'three_months':
          this.month = 3;
          this.fillData();
          break
        case 'six_months':
          this.month = 6;
          this.fillData();
          break
        case 'one_year':
          this.month = 12;
          this.fillData();
          break
        default:
      }
    },
    fillData() {
      this._service.getChartData(this.resourceId, this.month).then(response => {
        this.series = response;
      })
    }
  }
}
</script>
<style scoped="scoped">
button {
  background: #fff;
  color: #222;
  border: 1px solid #e7e7e7;
  border-bottom: 2px solid #ddd;
  border-radius: 2px;
  padding: 4px 17px;
}

button.active {
  color: #fff;
  background: #008FFB;
  border: 1px solid blue;
  border-bottom: 2px solid blue;
}

</style>